/*
 * @Descripttion: 
 * @version: 
 * @Author: Luochen
 * @Date: 2022-12-15 11:42:48
 * @LastEditors: Luochen
 * @LastEditTime: 2023-03-22 14:23:21
 */
const utils = {
    doubleNum(n) {
        const str = "" + n;
        return str.padStart(2, "0");
    },
    parseTime() {
        var times = (new Date()).getTime();
        var date = new Date();
        date.setTime(times);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        return [year, month].map(this.doubleNum).join("-");
    },
    parseTimeTo(time) {
        var times = time * 1000;
        var date = new Date();
        date.setTime(times);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var mins = date.getMinutes();
        var secs = date.getSeconds();
        return [year, month, day].map(this.doubleNum).join("-") + ' ' + [hour, mins, secs].map(this.doubleNum).join(":");
    },
    setStorage(key, user) {

        const JsonStr = JSON.stringify(user)
        window.sessionStorage.setItem(key, JsonStr)
    },
    getStorage(key) {
        var jsonStr = window.sessionStorage.getItem(key) || ''
        return jsonStr ? JSON.parse(jsonStr) : ''
    },
    delStorage(key) {
        window.sessionStorage.removeItem(key)
    },
    deepClone(obj){
        return obj ? JSON.parse(JSON.stringify(obj)) : ''
    },
    goLink(item){
        var link = item.link ? item.link : item.url 
        if(link&&link!=0){
            window.open(link)
        }
    }
}
export default {
    install: (app) => {
        app.config.globalProperties['$util'] = utils;
    }
}
