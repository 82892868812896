<template>
  <router-view />
</template>

<style lang="scss">
@import url('common/css/common.scss');
*{
  margin: 0;
}
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-family: PingFang SC;
}
</style>
