<template>
    <footer class="footer mt40">
        <div v-if="footBox==1">
            <div class="footer_one" v-if="show">
                <p class="title">请关注官方媒体矩阵</p>
                <div class="codeBox">
                    <div class="container">
                        <div class="row">
                            <div class="col" v-for="(item, inx) in codeList" :key="inx">
                                <div class="codeOne chang" style="max-width:150px;">
                                    <div class="codeImg">
                                        <img :src="item.img" alt="" class="img-fluid" style="max-width:80px">
                                    </div>
                                    <div class="codeBtm flexStart unset">
                                        <img :src="item.left_img" alt="" class="img-fluid" v-if="item.left_img">
                                        <div class="codeWord" v-html="item.content" v-if="item.left_img">
            
                                        </div>
                                        <div class="codeWord" v-else>{{ item.content }}</div>
                                    </div>
                                    <!-- <div class="codeWord active">
                                        {{ item.conNum }}
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer_one mb30" v-else>
                <div class="container">
                    <div class="row">
                        <div class="col" v-for="(item, inx) in conList" :key="inx">
                            <div class="codeOne active flexBtn" :class="{'acBg':item.type==1}">
                                <div class="coLeft">
                                    <div class="codeBtm flexStart unset">
                                        <img :src="item.icon" alt="" class="img-fluid" v-if="item.icon">
                                        <div class="codeWord">
                                            <div class="ftTitle">{{ item.title }}</div>
                                            <div v-html="item.f_title"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="codeImg">
                                    <img :src="item.img" alt="" class="img-fluid ftImg">
                                </div>
            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer_one active">
            <div class="container">
                <!-- v-if="screenWidth>1000" -->
                <div class="row" >
                    <div class="col col-lg-4 col-sm-12 footerBox">
                        <div class="footBtmLeft">
                            <div :class="['footBtmWord active mb10',{'textCenter':screenWidth<1000}]">联系我们</div>
                            <div :class="['footBtmWord',{'textCenter':screenWidth<1000}]">电话：{{about.phone}}</div>
                            <div :class="['footBtmWord',{'textCenter':screenWidth<1000}]">邮箱：{{about.email}}</div>
                            <div :class="['footBtmWord', {'textCenter':screenWidth<1000}]">
                                {{about.location}}
                                <!-- <div class="iconMap" v-if="screenWidth > 1000" @click="$router.push('/about')">
                                    <img src="@/assets/images/map.jpg" alt="">
                                </div> -->
                            </div>
                            <!-- <div :class="['footBtmWord',{'textCenter':screenWidth<1000}]">邮编: {{about.zip_code}}</div> -->
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col" v-for="(item, inx) in footList" :key="inx">
                                <div class="footCode">
                                    <img :src="item.img" alt="" class="img-fluid" style="max-width:60px">
                                    <div class="footBtmWord active textCenter">{{ item.comment }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- <div class="row" v-else>
                    <div class="col">
                        <div class="footBtmLeft">
                            <div class="footBtmWord active mb20">联系我们</div>
                            <div class="footBtmWord">电话：(0755) 8374 6161</div>
                            <div class="footBtmWord mb20">邮箱：info@illus-org.com</div>
                            <div class="footBtmWord">深圳市南山区南头古城中山南街71号</div>
                            <div class="footBtmWord mb20">邮编: 518000</div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col" v-for="(item, inx) in footList" :key="inx">
                                <div class="footCode">
                                    <p class="footBtmWord active textCenter">{{ item.title }}</p>
                                    <img :src="item.url" alt="" class="img-fluid">
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="row" id="btm">
                    <!-- <div class="footBtmWord flexStart">{{about.record}} 
                        <img src="@/assets/images/e6.png" alt=""
                            style="width:16px;vertical-align: middle;">{{ about.security_num }}
                        </div> -->
                    <div class="footBtmWord">{{ about.cop + about.support + about.record }}</div>
                </div>
            </div>

        </div>
        <div class="footerIcon" v-if="showIcon">
            <div class="top" @click="goTop">
                <img src="@/assets/images/top-fill.png" alt="">
            </div>
            <div class="bottom" v-if="hideBtm">
                <img src="@/assets/images/top-fill.png" alt="">
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    name: "header",
    props: {
        show: {
            type: Boolean,
            default: true
        },
        footBox: {
            type: Number,
            default: 1
        },
    },
    data() {
        return {
            showIcon:false,
            hideBtm:true,
            screenWidth: document.body.clientWidth,
            about:{},
            codeList: [
                {
                    code: require('@/assets/images/c1.png'),
                    icon: require('@/assets/images/d1.png'),
                    content: `插画协会<br>
                        微信服务号<br>illus2013`,
                    conNum: ''
                },
                {
                    code: require('@/assets/images/c2.png'),
                    icon: require('@/assets/images/d1.png'),
                    content: `ALILI ART<br>
                        微信公众号<br>aliliart`,
                    conNum: ''
                },
                {
                    code: require('@/assets/images/c3.png'),
                    icon: require('@/assets/images/d1.png'),
                    content: `插画加<br>
                        微信公众号`,
                    conNum: 'gh_53d70ac59e38'
                },
                {
                    code: require('@/assets/images/c4.png'),
                    icon: require('@/assets/images/d2.png'),
                    content: `插画协会<br>新浪微博`,
                    conNum: ''
                },
                {
                    code: require('@/assets/images/c5.png'),
                    icon: require('@/assets/images/d2.png'),
                    content: `ALILI ART<br>新浪微博`,
                    conNum: ''
                },
                {
                    code: require('@/assets/images/c6.png'),
                    icon: require('@/assets/images/d3.png'),
                    content: `插画协会<br>抖音`,
                    conNum: ''
                },
                {
                    code: require('@/assets/images/c7.png'),
                    icon: require('@/assets/images/d4.png'),
                    content: `插画协会<br>小红书`,
                    conNum: ''
                },
                {
                    code: require('@/assets/images/c7.png'),
                    icon: require('@/assets/images/d4.png'),
                    content: `野生小S<br>小红书`,
                    conNum: ''
                },
            ],
            conList: [
                {
                    title: '参观预约',
                    code: require('@/assets/images/e7.png'),
                    icon: '',
                    content: '博物馆本馆参观<br>需提前一天预约'
                },
                {
                    title: '服务咨询',
                    code: require('@/assets/images/c2.png'),
                    icon: require('@/assets/images/d1.png'),
                    content: '微信公众号'
                },
                {
                    title: 'ALILI ART',
                    code: require('@/assets/images/c2.png'),
                    icon: require('@/assets/images/d1.png'),
                    content: '微信公众号'
                },
                {
                    title: 'ALILI ART',
                    code: require('@/assets/images/c4.png'),
                    icon: require('@/assets/images/d2.png'),
                    content: '新浪微博'
                },
            ],
            footList: [
                {
                    title: '入会/会员服务',
                    url: require('@/assets/images/e1.png'),
                },
                {
                    title: '版权服务咨询',
                    url: require('@/assets/images/e2.png'),
                },
                {
                    title: '博物馆/ALILI咨询',
                    url: require('@/assets/images/e3.png'),
                },
                {
                    title: '观展预约',
                    url: require('@/assets/images/e4.png'),
                },
                {
                    title: '商店',
                    url: require('@/assets/images/e5.png'),
                },
            ]
        }
    },
    methods:{
        //点击回到顶部
        goTop(){
            $('html, body').animate({ scrollTop: 0 }, 100);
        },
        //官方媒体矩阵
        getIndexData() {
            this.$get('/api/get_index_mtjz', {}).then(res => {
                res.data.forEach(ele => {
                    if(ele.left_img){
                        ele.content = `${ele.shang}<br>${ele.zhong}<br>${ele.xia}`
                    }else{
                        ele.content = `${ele.shang}${ele.zhong}${ele.xia}`
                    }
                });
                this.codeList = res.data;
            }).catch(err => {

            })
        },
        
        get_index_bottom_qrcode() {
            //底部二维码
            this.$get('/api/get_index_bottom_qrcode', {}).then(res => {
                this.footList = res.data;
            }).catch(err => {

            })
            //底部联系我们
            this.$get('/api/get_index_contact_us', {}).then(res => {
                this.about = res.data[0];
            }).catch(err => {

            })
        },
        //博物馆底部二维码
        get_index_bwgqr() {
            this.$get('/api/get_index_bwgqr', {}).then(res => {
                res.data.forEach(ele => {
                    ele.f_title = ele.f_title.replace(/\n/g, '<br>')
                });
                this.conList = res.data;
            }).catch(err => {

            })
        },
    },
    mounted(){
        this.getIndexData();
        this.get_index_bottom_qrcode();
        this.get_index_bwgqr();
        // 获取元素距离顶部的距离跟可视区域的高度
       
        $(window).scroll( ()=> {
            var b_top = $('#btm').offset().top;
            var scrollTop = $(window).scrollTop();
            if (scrollTop > 500)
                this.showIcon =true
            else
                this.showIcon =false

            if (scrollTop - b_top>-1000){
                this.hideBtm=false
            }else{
                this.hideBtm = true
            }
        });
    }
}
</script>

<style scoped lang="scss">
.footerIcon{
    width: 64px;
    position: fixed;
    right: 20px;
    bottom: 100px;
    img{
        width: 100%;
    }
    .top{
        // margin-bottom: 20px;
        cursor: pointer;
        // width: 64px;
        // height: 64px;
        // border-radius: 50%;
        // box-shadow: 0 0 10px 10px rgba($color: #000000, $alpha: .5);
        // background: #fff;
    }
    .bottom{
        transform: rotate(180deg);
    }
}
.footer {
    widows: 100%;
    .footerBox{
        min-width: 300px;
    }

    .footBtmWord {
        font-weight: 300;
        color: #fff078;
        font-size: .8rem;
        line-height: 18px;
        text-align: left;
        font-weight: 300;
        min-width: 120px;
        position: relative;
       
        .iconMap{
            width: 30px;
            height: 30px;
            cursor: pointer;
            position: relative;
            top: 10px;
            // position: absolute;
            // right: -50px;
            // top: 10px;
            // margin-top: 30px;
            margin-left: 30px;
            img{
                width: 100%;
            }
        }

        &.active {
            // font-size: .8rem;
        }

        &.textCenter {
            text-align: center;
        }
    }

    .footer_one {
        &.active {
            background: #4d4d4d;
            padding: .2rem 0;
        }

        .title {
            background: #fff;
            padding: 1rem 0;
            color: #707070;
            font-size: 1.5rem;
            font-weight: bold;
            text-align: center;
        }

        .codeBox {
            background-color: #f0f0eb;
            // padding: 10px 0;

        }

        .codeOne {
            // min-width: 100px;
            margin: .6rem 1rem;
            &.chang{
display: flex;
            justify-content: center;
            flex-wrap: wrap;
            }
            

            &.active {
                padding: 1rem;
                background: #f0f0eb;
                margin: 1rem 0;
                &.acBg {
                        background: #fff078;
                    }
            }

            

            .coLeft {
                // flex: 1;
                min-width: 100px;
            }

            .codeImg {
                // width: 5rem;
                text-align: left;
                margin-bottom: 5px;
                .ftImg{
                    min-width: 113px;
                    max-width: 113px;
                }
            }

            .codeBtm {
                min-width: 80px;
                img {
                    width: 1.2rem;
                    height: 1.2rem;
                    margin-right: .5rem;
                }


            }

            .codeWord {
                text-align: left;
                color: #4d4d4d;
                line-height: .9rem;
                font-size: .5rem;
                .ftTitle{
                    font-size: 1rem;
                    font-weight: bold;
                    margin-bottom: 5px;
                }

                &.active {
                    width: 100%;
                }
            }
        }

        .footCode {
            min-width: 100px;
            margin-top: 5px;
            margin-bottom: 6px;

            img {
                max-width: 100px;
            }
        }

    }
}
</style>
