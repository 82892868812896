<!--
 * @Descripttion: 
 * @version: 
 * @Author: Luochen
 * @Date: 2022-11-16 16:55:33
 * @LastEditors: Luochen
 * @LastEditTime: 2023-03-22 14:22:52
-->
<template>
  <div class="home">
    <Header :banner="bannerList" />
    <div class="container index_one" style="margin-top:-20px;position: relative;z-index: 98;">
      <div class="row">
        <div class="col mb30" v-for="(item, inx) in zbwnList" :key="inx">
          <div class="index_top">
            <p class="p_title">{{ item.y_title }}</p>
            <p class="p_cn">{{item.title}}</p>
          </div>
          <div class="index_btm">
            <p class="p_con" v-for="(val,i) in item.content" :key="i">{{val}}</p>
          </div>
        </div>
        <!-- <div class="col mb30">
          <div class="index_top">
            <p class="p_title">PROMOTE</p>
            <p class="p_cn">插画推广</p>
          </div>
          <div class="index_btm">
            <p class="p_con">举办展览讲座/论坛活动/国际交流</p>
            <p class="p_con">推动插画与产业对接融合</p>
            <p class="p_con">插画行业及市场调研和探索</p>
            <p class="p_con">综合媒体宣传/跨界创新</p>
          </div>
        </div>
        <div class="col mb30">
          <div class="index_top">
            <p class="p_title">MEMBERS</p>
            <p class="p_cn">会员支付</p>
          </div>
          <div class="index_btm">
            <p class="p_con">提升插画师会员社会地位</p>
            <p class="p_con">组织会员展览讲座/学习交流</p>
            <p class="p_con">提供专业版权登记服务</p>
            <p class="p_con">增加会员曝光推广/业务对接</p>
          </div>
        </div>
        <div class="col">
          <div class="index_top">
            <p class="p_title">PRAISE</p>
            <p class="p_cn">表彰卓越</p>
          </div>
          <div class="index_btm">
            <p class="p_con">搭建国际性插画峰会/博览会舞台</p>
            <p class="p_con">通过专业大赛发掘表彰优秀人才</p>
            <p class="p_con">激励行业品牌插画应用</p>
            <p class="p_con">表彰为行业杰出贡献的团体与个人</p>
          </div>
        </div> -->
      </div>
    </div>
    <div class="container">
      <p class="p_title_name">专业权威的插画行业资源平台</p>
    </div>
    <div class="container">
      <div class="imgBox">
        <div class="row">
          <div class="col" @click="$util.goLink(item)" v-for="(item, inx) in zyptList" :key="inx">
            <img :src="item.icon" alt="" class="inxImg">
          </div>
          <!-- <div class="col" @click="goDetail('/projects')">
            <img src="@/assets/images/a1.png" alt="" class="inxImg">
          </div>
          <div class="col" @click="goDetail('/projects')">
            <img src="@/assets/images/a2.png" alt="" class="inxImg">
          </div>
          <div class="col" @click="goDetail('/projects')">
            <img src="@/assets/images/a3.png" alt="" class="inxImg">
          </div>
          <div class="col" @click="goDetail('/platform')">
            <img src="@/assets/images/a4.png" alt="" class="inxImg">
          </div>
          <div class="col" @click="goDetail('/museum')">
            <img src="@/assets/images/a5.png" alt="" class="inxImg">
          </div>
          <div class="col" @click="goDetail('/platform')">
            <img src="@/assets/images/a6.png" alt="" class="inxImg">
          </div> -->
        </div>
      </div>

    </div>
    <div class="container mt40">
      <div class="row">
        <div class="col col-sm-3" v-for="(item, inx) in zyptBigList" :key="inx">
          <div class="index_two_one" @click="$util.goLink(item)">
            <img :src="item.bg" alt="" class="img-fluid">
            <div class="index_two flexBtn width" v-if="item.xhsl > 0">
              <div class="inFl active">
                <div class="index_two_con flexStart">
                  {{ item.xhsl }} <span class="span">+</span>
                </div>
                <div class="index_two_con active textLeft">
                  协会会员
                </div>
              </div>
              <div class="inFl">
                <div class="index_two_con flexCenter">
                  {{ item.zcyh }} <span class="span">+</span>
                </div>
                <div class="index_two_con active textCenter">
                  注册用户
                </div>
              </div>
            </div>
            <div class="index_two" v-else>
              <div class="index_two_con flexStart">
                {{ inx == 1 ? item.zpzy : inx ==2 ? item.chnx : item.snz }} <span class="span">{{ inx == 1
                    ? '+' : 'years'
                }}</span>
              </div>
              <div class="index_two_con active">
                {{ inx == 1 ? '插画作品资源' : inx == 2 ? '中国内地最早插画组织' : '全国插画双年展' }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col">
          <div class="index_two_one" @click="goDetail('/member')">
            <img src="@/assets/images/b1.png" alt="" class="img-fluid">
            <div class="index_two flexBtn width">
              <div class="inFl active">
                <div class="index_two_con flexStart">
                  4000 <span class="span">+</span>
                </div>
                <div class="index_two_con active textLeft">
                  协会会员
                </div>
              </div>
              <div class="inFl">
                <div class="index_two_con flexCenter" >
                  4万 <span class="span">+</span>
                </div>
                <div class="index_two_con active textCenter">
                  注册用户
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="index_two_one" @click="goDetail('/member')">
            <img src="@/assets/images/b2.png" alt="" >
            <div class="index_two">
              <div class="index_two_con flexStart">
                40万 <span class="span">+</span>
              </div>
              <div class="index_two_con active">
                插画作品资源
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="index_two_one" @click="goDetail('/about')">
            <img src="@/assets/images/b3.png" alt="">
            <div class="index_two">
              <div class="index_two_con flexStart">
                15 <span class="span">years</span>
              </div>
              <div class="index_two_con active">
                中国内地最早插画组织
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="index_two_one" @click="goDetail('/projects')">
            <img src="@/assets/images/b4.png" alt="" class="img-fluid">
            <div class="index_two">
              <div class="index_two_con flexStart">
                16 <span class="span">years</span>
              </div>
              <div class="index_two_con active">
                全国插画双年展
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="container mt10">
      <div class="row">
        <div class="col" v-for="(item, i) in advList" :key="i">
          <div class="cursor advertisement">
            <img :src="item.img" alt="" @click="$util.goLink(item)">
          </div>
        </div>
      </div>
    </div>
    <!-- <swiper></swiper> -->
    <Footer></Footer>
  </div>
</template>

<script>
import swiper from '../components/swiperCom.vue';
import '@/common/css/index.scss'
export default {
  name: "Home",
  data() {
    return {
      bannerList: [],
      advList: [],
      zyptList: [],
      zyptBigList: [],
      zbwnList: [],
    }
  },
  components: {
    swiper
  },
  methods: {
    //首页跳转
    goDetail(url, id) {
      if(url==0){
        return
      }
      this.$router.push({
        url,
        query: {
          id,
        }
      })
    },
    //获取首页轮播图
    getIndexData() {
      this.$get('/api/get_index_banner', {}).then(res => {
        this.bannerList = res.data;
      }).catch(err => {

      })
    },
    //获取首页广告位
    get_index_adv() {
      this.$get('/api/get_index_adv', {}).then(res => {
        this.advList = res.data;
      }).catch(err => {

      })
    },
    //广告位点击
    advClick(url) {
      if(url==0){
        return
      }
      window.open(url)
    },
    //获取资源平台
    get_index_zypt() {
      this.$get('/api/get_index_zypt', {}).then(res => {
        this.zyptList = res.data;
      }).catch(err => {

      })
      this.$get('/api/get_index_zyptdt', {}).then(res => {
        this.zyptBigList = res.data;
      }).catch(err => {

      })
    },
    //获取首页中部文案
    get_index_zbwa() {
      this.$get('/api/get_index_zbwa', {}).then(res => {
        res.data.forEach(ele => {
          ele.content=ele.content.split('\n')
        });
        this.zbwnList = res.data;
      }).catch(err => {

      })
    },

  },
  mounted() {
    this.getIndexData();
    this.get_index_adv();
    this.get_index_zypt();
    this.get_index_zbwa();
  }
};
</script>
<style>

</style>
