<template>
  <div>
    <header v-if="show" class="header active">
      <div class="header_con container flexBtn">
        <div class="header_left small cursor" @click="$router.push('/')">
          <img alt="" src="@/assets/images/small.png">
        </div>
        <!-- <div class="header_right">
          <ul class="ul flexEnd">
            <li class="menu small flexCenter">EN</li>
          </ul>
        </div> -->
      </div>
    </header>
    <div v-else>
      <!-- 定义导航条 -->
      <nav class="navbar navbar-expand-lg navbar-light header">
        <div class="container-fluid header_con flexBtn">
          <div class="header_left cursor navbar-brand" @click="$router.push('/')">
            <img alt="" src="@/assets/images/logo.png">
          </div>
          <button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
            class="navbar-toggler" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse" type="button">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div id="navbarSupportedContent" class="collapse navbar-collapse header_right">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 d-flex ul">
              <li v-for="(item, inx) in menuList" :key="inx"
                :class="['menu flexCenter cursor flexStart', { 'active': $route.path == item.url }]"
                @click="menuClick(item.url, item.type)">
                {{ item.title }}
              </li>
              <!-- <li :class="['menu flexCenter cursor flexStart', { 'movie': screenWidth < 1000 }]">EN</li> -->
            </ul>
          </div>
        </div>
      </nav>
      <div class="navBox"></div>
      <div class="banner" v-if="banner.length == 0">
        <img :src="url" alt="" class="img-fluid smImgMobile">
      </div>
      <!-- Swiper -->
      <!-- <div class="swiper-container" v-else>
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in banner" :key="item.id">
            <div class="banner">
              <img :src="item.pic" alt="" class="img-fluid">
            </div>
          </div>
        </div>
      </div> -->

      <!-- :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }"
      :pagination="{ clickable: true }" -->
      <swiper class="swiper-container" :autoplay="{ autoplay: true }" loop v-else>
        <swiper-slide class="swiper-slide" v-for="(item, i) in banner" :key="i">
          <div class="banner" @click="$util.goLink(item)">
            <img :src="item.pic|| item.img" alt="" class="img-fluid">
          </div>
        </swiper-slide>
        <!-- 如果需要分页器 -->
        <!-- <div class="swiper-pagination"></div> -->
        <!-- 如果需要导航按钮 左右箭头。如果放置在swiper-container外面，需要自定义样式。-->
        <!-- <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div> -->
        <!-- 如果需要滚动条 -->
        <!-- <div class="swiper-scrollbar"></div> -->
      </swiper>
    </div>
    <div class="showWechat flexCenter" v-if="showWechat" @click="showWechat=false;">
      <img src="@/assets/images/wechat.jpg" alt="">
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
// 导入样式
import "swiper/swiper-bundle.css";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
export default {
  name: "header",
  props: {
    url: {
      type: String,
      default: "",
    },
    banner: {
      type: Array,
      default: [],
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      showWechat:false,
      menuList: [
        {
          title: "主页",
          url: '/'
        }, {
          title: "关于",
          url: '/about'
        }, {
          title: "会员",
          url: '/member'
        }, {
          title: "动态",
          url: '/dynamic'
        }, {
          title: "常设项目",
          url: '/projects'
        }, {
          title: "版权平台",
          type: 1,
          url: 'http://cicp.illusplus.com/#/pages/wechat/callWXClient'
        }, {
          title: "博物馆",
          url: '/museum'
        }, {
          title: "商店",
          type: 2,
          url: '/shop'
        },
      ]
    }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Autoplay, Scrollbar, A11y],
    };
  },
  methods: {
    menuClick(url, type) {
      if (type == 1) {
        window.open('http://cicp.illusplus.com/#/pages/wechat/callWXClient')
        this.$router.push('/')
      } else if (type==2) {
        this.showWechat=true;
      } else {
        this.$router.push(url)
      }

    },
    myswiper() {
      var swiper = new Swiper('.swiper-container', {
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        autoplay: true,
        loop: true
      });
    }
  },

  mounted() {
    this.$nextTick(() => {
      // this.myswiper();
    })
  }
}
</script>

<style lang="scss" scoped>
/deep/.swiper-pagination-bullet-active {
  background: white;
}
.showWechat{
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  background: rgba($color: #000000, $alpha: .5);
  img{
    // border-radius: 50%;
  }
}

.banner {
  width: 100vw;
}

@media (max-width: 575.98px) {
  // .smImgMobile {
  //   width: auto;
  //   height: 8rem;
  //   }
}


.navbar-collapse {
  flex-grow: 0;
}

.navBox {
  width: 100%;
  height: 80px;
  background: #fff078;
}

.header {
  width: 100%;
  // height: 80px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  background: #fff078;

  &.active {
    position: relative;
  }

  .header_con {
    // max-width: 1200px;
    margin: 0 auto;

    // height: 100%;
    .header_left {
      img {
        width: 230px;
      }

      &.small {
        img {
          width: 44px;
        }
      }

    }

    .header_right {

      // height: 100%;
      .ul {
        // height: 100%;
        margin-bottom: 0;
        padding: 10px 0;
        box-sizing: border-box;
      }

      .menu {
        color: #4d4d4d;
        font-weight: 500;
        font-size: 20px;
        // height: 100%;
        cursor: pointer;
        padding: 15px;
        box-sizing: border-box;
        // margin-left: 5px;
        border-radius: 5px;
        transition: all .3s;

        &:hover,
        &.active {
          background: #4d4d4d;
          color: #fff;
        }

        &:last-child {
          // margin-left: 50px;
        }

        &.movie {
          margin-left: 0 !important;
        }

        &.small {
          padding: 5px;
          border: 1px solid #4d4d4d;
          border-radius: 5px;
        }
      }
    }
  }
}
</style>
