import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
// import $ from 'jquery'

import Header from "./components/header.vue";
import Footer from "./components/footer.vue";
import Menu from "./components/menu.vue";
import Axios from './utils/request.js';
import $util from './utils/utils';

const app = createApp(App)

app.component('Header',Header);
app.component('Footer',Footer);
app.component('Menu',Menu);

app.use(store).use(router).use(ElementPlus).use(Axios).use($util).mount("#app");
