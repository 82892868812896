import { createRouter, createWebHashHistory } from "vue-router";
import index from "../views/index.vue";

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
    meta: {
      title: '深圳市插画协会',
    },
  },
  {
    path: "/about",
    name: "about",
    meta: {
      title: '深圳市插画协会',
    },
    component: () =>import("../views/about/index"),
  },{
    path: "/member",
    name: "member",
    meta: {
      title: '深圳市插画协会',
    },
    component: () =>
      import("../views/member/index"),
  },{
    path: "/dynamic",
    name: "dynamic",
    meta: {
      title: '深圳市插画协会',
    },
    component: () =>
      import("../views/dynamic/index"),
  },{
    path: "/projects",
    name: "projects",
    meta: {
      title: '深圳市插画协会',
    },
    component: () =>
      import("../views/projects/index"),
  },{
    path: "/platform",
    name: "platform",
    meta: {
      title: '深圳市插画协会',
    },
    component: () =>
      import("../views/platform/index"),
  },{
    path: "/museum",
    name: "museum",
    meta: {
      title: '深圳市插画协会',
    },
    component: () =>
      import("../views/museum/index"),
  },{
  },{
    path: "/detail/:id",
    name: "detail",
    meta: {
      title: '深圳市插画协会',
    },
    component: () =>
      import("../views/museum/detail"),
  },{
  },{
    path: "/detailWord",
    name: "detailWord",
    meta: {
      title: '深圳市插画协会',
    },
    component: () =>
      import("../views/museum/detailWord"),
  },{
    path: "/shop",
    name: "shop",
    meta: {
      title: '深圳市插画协会',
    },
    component: () =>
      import("../views/shop/index"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach(async (to) => {
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title;
  }
});

export default router;
