<template>
    <div>
        <swiper class="swiper-container"  :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }"
      :pagination="{ clickable: true }"
      :autoplay="{ autoplay: true }"
      loop>
            <swiper-slide class="swiper-slide proBanner cursor" v-for="(item, i) in imgs" :key="i">
                <img :src="item.img" alt="" @click="$util.goLink(item)"/>
            </swiper-slide>
            <!-- 如果需要分页器 -->
            <!-- <div class="swiper-pagination" v-if="pagination"></div> -->
            <!-- 如果需要导航按钮 左右箭头。如果放置在swiper-container外面，需要自定义样式。-->
            <div class="swiper-button-prev" v-if="swiperButton"></div>
            <div class="swiper-button-next" v-if="swiperButton"></div>
            <!-- 如果需要滚动条 -->
            <div class="swiper-scrollbar" v-if="scrollbar"></div>
        </swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Autoplay,
} from "swiper";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
export default {
    components: {},
    props:{
        imgs:[],
        pagination:false,
        swiperButton:false,
        scrollbar:false,
    },
    data() {
        return {
            // imgs: [
            //     require("@/assets/images/banner.png"),
            //     require("@/assets/images/banner.png"),
            // ],
        }
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log('slide change');
        };
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, Pagination, Scrollbar, A11y],
        };
    },
    methods: {

    },
    mounted() {

    },
}
</script>
<style lang="scss" scoped>
/* .swiper-container {
  width: 100vw;
  height: 300px;
  --swiper-theme-color: #ff6600;
  --swiper-pagination-color: #248cc0;
  --swiper-navigation-color: #248cc0;
  --swiper-navigation-size: 20px;
}
.swiper-slide img{
  width: 100%;
} */
.proBanner{
  width: 100%;
  img{
    width: 100%;
  }
}
</style>
