<template>
  <div class="menuBox mb40" id="navbar-example">
    <div class="container">
      <div class="row justify-content-start">
        <ul class="nav nav-pills">
          <li class="nav-item cursor" v-for="(item,inx) in menuList" :key="inx" @click="go(item.id,1)">
            <span :class="['nav-link', {'active':selMenu==item.id}]">{{ item.name }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "menu",
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      selMenu: ''
    }
  },
  methods: {
    go(id, type) {
      if(id=='商店'){
        //博物馆的商店按钮
      }else{
        this.selMenu = id;
        document.getElementById(id)?.scrollIntoView();
      }
      this.$emit('fun', id);
     
      /*或
      document.querySelector("#idshow").scrollIntoView();
      或
      this.$refs.view.scrollIntoView();
      */
    }
  },
  updated(){
    console.log(this.type);
    if (this.type) {
      this.selMenu = this.type
    } 
  },
  mounted() {
    this.selMenu = this.menuList[0].id
    
  }
}
</script>

<style scoped lang="scss">
.nav-link {
  color: #fff;
  font-size: 14px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background: #797979;
  border-radius: 0;
}
</style>
